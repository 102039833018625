import React, { useContext } from "react";
import { motion } from "framer-motion";
import { PusherContext } from "../Context";
import styled from "styled-components";
import treasureChest from "../assets/team-chest.png"

export const TeamWrapperScore = styled(motion.div)`
  position: relative;
  flex:1;
  max-width: 320px;
  z-index: 1;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TeamList = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding-left: 0;


`;

export const TeamLogo = styled(motion.div)`
  position: relative;
  padding: 20px;
  z-index: 1;
`;

export const TeamLogoScore = styled(motion.img)`
  width: 100px;
`;

export const ScoreWrapper = styled(motion.div)`
  position: relative;
`


export const Chest = styled(motion.img)`
  width:  200px;
`;


export const Score = styled(motion.div)`
  position: absolute;
  top: 10%;
  right:0;
  z-index:1;;
  font-family: PoppinsSemiBold;
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  
`;

const teamWapperScore = {
  open: {
    opacity: 1,
    scale: 1,
    transition: { stiffness: 100 },
  },
  closed: {
    opacity: 0,
    scale: 0.5,
    transition: {  stiffness: 100 },
  },
};

export const Teams = () => {
  const { me, teams } = useContext(PusherContext);
  const team = teams.find(item => item.name === me.info.team);

  return (
    <TeamList
    key={'teams'}>
      <TeamWrapperScore
            variants={teamWapperScore}
            as={motion.div}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
          >
            <TeamLogoScore
              key={me.info.team}
              as={motion.img}
              src={require(`../assets/teams/${me.info.team}.png`)}
              alt={`Team ${me.info.team}`}
            />
            <ScoreWrapper>
              <Chest 
                key={me.info.team}
                as={motion.img}
                src={treasureChest}
                alt={`Team ${me.info.team} Treasure chest`}
              />
              <Score>
                 {team.score.toFixed(2)}
              </Score>
            </ScoreWrapper>
          </TeamWrapperScore>
    </TeamList>
  );
};
