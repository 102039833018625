import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { PusherContext } from "../Context";
import coin from "../assets/coin.gif";
import styled from "styled-components";
import fire from "../assets/fire.gif";
import gem from "../assets/coin-static.png";

export const CorrectAnswer = styled(motion.img)`
  width: 25px;
  height: 25px;
  position: relative;
  top: -5px;
`;

export const Fire = styled(motion.img)`
  width: 45px;
  height: 45px;
`;

export const Wrapper = styled(motion.div)`
  position: absolute;
  z-index: 1;
  bottom: -5px;
  right: -15px;
`;

export const Multiplier = styled(motion.div)`
  position: relative;
  top: -10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
  height: 100%;
  font-size: 18px;
  font-family: "BarlowBoldItalic";
  span{
    font-size: 12px;
  }
`;

// export const Streak = styled(motion.div)`
//   p
//   top: 0;
// `

const coinVariants = {
  open: {
    y: -100,
    opacity: 0,
    transition: {
      type: "spring",
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      y: { stiffness: 1000 },
    },
  },
};

const ScoreStatus = ({ membersAnswer, membersId }) => {
  const { correctAnswer, step, setAnswerStreak, answerStreak, menuExpanded} = useContext(PusherContext);
  const [isTriggered, setIsTriggered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsTriggered(true);
    }, 3000);
    let updatedStreak = answerStreak
    if(membersAnswer?.answer && membersAnswer.answer === correctAnswer){
      updatedStreak[membersId]++    
    }else{
      updatedStreak[membersId] = 0 
    }
    setAnswerStreak({...updatedStreak})
  }, [correctAnswer]);

  return (
    <Wrapper>
      {!menuExpanded && membersAnswer?.answer && membersAnswer.answer === correctAnswer && (
        <>
          {answerStreak[membersId] > 2 ? (
            <Multiplier><span>X</span>{answerStreak[membersId]}</Multiplier>
          ) : (
            <CorrectAnswer as={motion.img} src={coin} />
          )}
        </>
      )}
    </Wrapper>
  );
};
export default ScoreStatus;