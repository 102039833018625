import React, { useState, useEffect, useContext } from "react";
import * as components from "../questions";
import { PusherContext } from "../Context";
import { motion } from "framer-motion";
import styled from "styled-components";
import sparklingStars from "../assets/sparkling-stars.gif";
import { FireAnimation } from "./FireAnimation";

export const Container = styled(motion.div)`
  width: 100%;
  height: calc(100% - 117px);
  overflow: hidden;
  position: absolute;
  font-family: "BarlowBoldItalic";
`;

export const Answer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  font-family: "BarlowBoldItalic";
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color:  ${({$onFire}) => $onFire ? '#ffd326': 'black' };
  ${({$onFire}) => $onFire ? 'text-shadow: 4px 2px #FF5722;' : ''}
  z-index: 1;
`;

export const AnswerWrapper = styled(motion.div)`
  width: 100%;
  position: absolute;
  font-family: "BarlowBoldItalic";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(-5deg);
  font-size: 60px;
  bottom: 15vh;
  
`;

export const Stars = styled(motion.img)`
  width: 100%;
  max-width: 375px;
  overflow: hidden;
  position: absolute;
  font-family: "BarlowBoldItalic";
  z-index: 0;
`;

export const AnswerStreak = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  z-index: 1;
  font-family: "BarlowBoldItalic";
  display: flex;
  align-items: center;
  justify-content: center;
  color:  ${({$onFire}) => $onFire ? '#ffd326': 'white' };
  ${({$onFire}) => $onFire ? 'text-shadow: 4px 2px #FF5722;' : ''}
  font-size: 30px;
`;

export const FireWrapper = styled(motion.div)`
position: absolute;
bottom: -50px;

`
const AnswerComponent = () => {
  const { correctAnswer, answerStreak, me, scoreboard} =
    useContext(PusherContext);
  return (
    <Container
      as={motion.div}
      key={"question"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <AnswerWrapper>
      {scoreboard[me.id]?.answer === correctAnswer ? (<>
        { answerStreak[me.id] > 2 ?  <FireAnimation/> : <Stars src={sparklingStars} /> }
        { answerStreak[me.id] > 2 ? (<Answer $onFire={answerStreak[me.id] > 3} >YOU'RE ON FIRE</Answer>) : (<Answer $onFire={answerStreak[me.id] > 3} >CONGRATS</Answer>)}
         <AnswerStreak $onFire={answerStreak[me.id] > 2} >{answerStreak[me.id]} IN A ROW</AnswerStreak>
      </>) : (
          <Answer $onFire={answerStreak[me.id] > 2} >NOPE SORRY NO LUCK</Answer>
      )}
      </AnswerWrapper>
    </Container>
  );
};

export default AnswerComponent;
