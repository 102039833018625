import React from "react";

export const HomeIcon = ({color}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18.0556C5 11.2984 5.73659 11.77 9.70149 8.092C11.4362 6.6952 14.1355 4 16.4664 4C18.7962 4 21.5494 6.682 23.2997 8.092C27.2646 11.77 28 11.2984 28 18.0556C28 28 25.6499 28 16.5 28C7.35015 28 5 28 5 18.0556Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.9619C12 20.5477 12.3358 20.2119 12.75 20.2119H19.728C20.1422 20.2119 20.478 20.5477 20.478 20.9619C20.478 21.3761 20.1422 21.7119 19.728 21.7119H12.75C12.3358 21.7119 12 21.3761 12 20.9619Z"
        fill="white"
      />
    </svg>
);

