import React, { useContext, useRef, useState, useEffect } from "react"; 
import Interface from "../components/Interface";
import LiveStream from "../components/LiveStream";
import QuestionComponent from "../components/QuestionComponent";
import AnswerComponent from "../components/AnswerComponent";
import { PusherContext } from "../Context";


const Client = () => {
const { step, isAuthorized} = useContext(PusherContext);
  return (
    <>
      {<LiveStream />}
      {isAuthorized && step === "question" && <QuestionComponent />}
      {isAuthorized && step === "answer" && <AnswerComponent />}
      {isAuthorized && <Interface />}
    </>
  );
};

export default Client;
