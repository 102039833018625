import React, { useContext } from "react";
import { motion } from "framer-motion";
import { PusherContext } from "../Context";
import styled from "styled-components";
import { Container } from "../Styles";
import { HomeIcon } from "../icons/HomeIcon";
import { CompassIcon } from "../icons/CompassIcon";
import { CurrentUserAvatar } from "./CurrentUserAvatar";
import { WaveAnimation } from "./WaveAnimation";

import { MyTeamLeaderboard } from "./MyTeamLeaderboard";
import Button from "./Button";
import { CloseIcon } from "../icons/CloseIcon";
import { Score } from "./Score";
import { Settings } from "./Settings";

export const StatusBar = styled(motion.div)`
  position: fixed;
  width: 100%;
  bottom: 0;

  display: flex;
  flex-direction: column;
`;

export const Controls = styled(motion.div)`
  display: flex;
  justify-content: space-evenly;
  flex-basis: calc(50% - 32px);
  align-items: start;
`;

export const Top = styled(motion.div)`
  display: flex;
  ${({$menuExpanded}) => $menuExpanded && "margin: 10px 0"};
`;


const statusbar = {
  open: {
    height: "calc(100% - 52.2px)",
    backgroundColor: "white",
    transition: { staggerChildren: 0.07, delayChildren: 1.2 },
  }, 
  closed: {
    height: "90px",
    backgroundColor: "transparent",
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Interface = () => {
  const { menuExpanded, setMenuExpanded, currentTab, setCurrentTab} =
    useContext(PusherContext);
  const handleTab = (tab) => {
    setMenuExpanded(true);
    setCurrentTab(tab);
  };

  const handleClose = () => {
    setMenuExpanded(false);
    setCurrentTab('leaderboard');
  };
  
  return (
    <>
      <WaveAnimation/>
      <StatusBar
        variants={statusbar}
        initial={"closed"}
        $StatusBar={menuExpanded}
        animate={menuExpanded ? "open" : "closed"}
      >
        { menuExpanded && 
        <Button handleButtonClick={() => handleClose("profile")} type={'close'} >
          <CloseIcon color={'#FFFFFF'} />
        </Button> 
        }
        <Top $menuExpanded={menuExpanded}>
          <Controls>
            <Button handleButtonClick={() => handleTab("profile")}>
              <HomeIcon
                color={currentTab === "profile" ? "#707070" : "#CECAD8"}
              />
            </Button>
            <Button handleButtonClick={() => handleTab("leaderboard")}>
              <CompassIcon
                color={currentTab === "leaderboard" ? "#707070" : "#CECAD8"}
              />
            </Button>
          </Controls>
          <CurrentUserAvatar />
          <Score />
        </Top>
        {currentTab === "leaderboard" && <MyTeamLeaderboard /> }
        {currentTab === "profile" && <Settings/> }
      </StatusBar>
    </>
  );
};
export default Interface;
