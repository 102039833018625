import React, { useContext } from "react";
import { PusherContext } from "../Context";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PlayIcon } from "../icons/PlayIcon";

export const Wrapper = styled(motion.button)`
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  z-index: 2;
`

const StreamPrompt = () => {
  const { isStreaming, setIsStreaming, player  } =
  useContext(PusherContext);
  return (
    <Wrapper
        as={motion.button}
        onClick={() => {
            setIsStreaming(!isStreaming);
            player?.play();
        }}
    >
        <PlayIcon color={"white"} /> 
    </Wrapper>
  )
}
export default StreamPrompt 

