import React, {useState} from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const RewardVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  enlarged: {
    width: '90vw',
    height: '90vh',
    position: 'absolute',
    top: 0
    
  }
};

export const Wrapper = styled(motion.li)`
    list-style: none;
    margin-bottom: 20px;

    
    border-radius: 12px;
   
`;

export const Content = styled(motion.div)`
width: 60vw;
height: 100%;
background: #529CFF;
border-radius: 12px;
margin: auto 8px;
`





export const Reward = ({ member, scrollRef, handleOnClick, isScrollable }) => {

  const [isClicked, setIsClicked] = useState(false)
  console.log(isClicked)
  return (
    <Wrapper
      as={motion.li}
      variants={RewardVariants}
      initial={isScrollable && { scale: 0.8, opacity: 0 }}
      animate={isClicked && 'enlarged'}
      whileInView={isScrollable && { scale: 1, opacity: 1 }}
      viewport={{ root: scrollRef }}
      amount={"all"}
      onClick={() => setIsClicked(!isClicked)}
    >
     <Content></Content>
    </Wrapper>
  );
};
