import React, { useContext, useEffect, useState } from "react";
import { PusherContext } from "../Context";
import { motion } from "framer-motion";
import styled from "styled-components";
import gem from "../assets/coin-static.png"
import constants from "../constants.json";
import CountUp from "react-countup";

export const Wrapper = styled(motion.div)`
  flex: 1;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
  flex-basis: calc(50% - 32px);
  font-size: 20px;
`;

export const Gem = styled(motion.img)`
height: 30px;
width :30px;
background-color: white;
border-radius: 50%;
`

export const Points = styled(motion.div)`
  margin-left:16px;
  font-family: PoppinsSemiBold;
  width: 50px;

`
export const Score = () => {
  const { me, scoreboard ,  step} =
  useContext(PusherContext);
  const [score, setScore] = useState()
  const [oldScore, setOldScore] = useState(20)


  useEffect(() => {
    setOldScore(parseInt(me.info.score))
    setScore(parseInt(me.info.score))
  },[])
 
  useEffect(() => {
    //extract new score total if exists
    console.log(me, scoreboard)
    let userNewScore = Object.keys(scoreboard) 
      .filter((key) => key === me.id)
      .reduce((cur, key) => { return Object.assign(cur, { [key]: scoreboard[key] })}, {});
   
    // update current score to be oldscore
    console.log(userNewScore,  step )
    if(Object.keys(userNewScore).length !== 0 && step === 'answer'){
      setOldScore(score)
      let usersScore = ((constants.questionTimeframe - userNewScore[me.id].time) * constants.questionMultiplier) + score;
      setScore(usersScore)
    }

  },[scoreboard[me.id], step])

  return (
    <Wrapper>
        <Gem
          as={motion.img}
          src={gem}
          alt={""}
          width="100%"
        />
        {score &&  <Points><CountUp duration={3} className="counter" start={oldScore} end={score} /></Points> }
    </Wrapper>
  )
}


