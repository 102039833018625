import React from "react";

export const CloseIcon = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M27.233 3.33203C32.883 3.33203 36.6663 7.2987 36.6663 13.1987V26.817C36.6663 32.7004 32.883 36.6654 27.233 36.6654H12.783C7.13301 36.6654 3.33301 32.7004 3.33301 26.817V13.1987C3.33301 7.2987 7.13301 3.33203 12.783 3.33203H27.233ZM25.0163 14.9504C24.4497 14.382 23.533 14.382 22.9497 14.9504L19.9997 17.9154L17.033 14.9504C16.4497 14.382 15.533 14.382 14.9663 14.9504C14.3997 15.517 14.3997 16.4504 14.9663 17.0154L17.933 19.9837L14.9663 22.9337C14.3997 23.517 14.3997 24.4337 14.9663 24.9987C15.2497 25.282 15.633 25.4337 15.9997 25.4337C16.383 25.4337 16.7497 25.282 17.033 24.9987L19.9997 22.0504L22.9663 24.9987C23.2497 25.3004 23.6163 25.4337 23.983 25.4337C24.3663 25.4337 24.733 25.282 25.0163 24.9987C25.583 24.4337 25.583 23.517 25.0163 22.9504L22.0497 19.9837L25.0163 17.0154C25.583 16.4504 25.583 15.517 25.0163 14.9504Z"
      fill={color}
    />
  </svg>
);
