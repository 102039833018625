import React, {useState, useRef, useEffect} from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import {Reward} from './Reward';
import { BellIcon } from "../icons/BellIcon";

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

`;

export const RewardsList = styled(motion.ul)`
    scroll-behavior: smooth;
    flex:1;
    display: flex;
    overflow: scroll;
`;


const RewardListVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  

export const Heading = styled(motion.h3)`
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
`;

export const Rewards = () => {

const [isScrollable, setIsScrollable] = useState(false);
const [isOpen, setIsOpen] = useState(false);
const scrollRef = useRef(null);

    useEffect(() => {
    setIsOpen(true)
  }, [])
  return (
    <Wrapper>
      <Heading><span>Rewards</span><BellIcon color={'black'} /></Heading>
      <RewardsList
       as={motion.ul} 
       initial={"closed"}
       animate={isOpen ? "open" : "closed"}
       variants={RewardListVariants} 
       ref={scrollRef}
       onAnimationComplete={() => setIsScrollable(true)}
      >
        {Array.from(Array(10), (e, i) => {
            return <Reward
                    scrollRef={scrollRef} 
                    isScrollable={isScrollable}
                    key={i}
                    > 
                    </Reward>
        })}
        
         <Reward
            scrollRef={scrollRef} 
            isScrollable={isScrollable}
        ></Reward>
        
      </RewardsList>   
    </Wrapper>
  );
};

