import { motion } from "framer-motion";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { PusherContext } from "../Context";
import Cookies from "js-cookie";
import box from "../assets/team-box.gif";


const teamAnimation = {
  hidden: { opacity: 1, scale: 1, translateX: "-50%" },
  visible: { opacity: 1, scale: 2, translateX: "-50%", translateY: "-30px" },
};

const boxAnimation = {
  hidden: { opacity: 0, scale: 0.5, },
  visible: { opacity: 1, scale: 1 },
};

const elements = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const button = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const TeamSelectWrapper = styled(motion.div)`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Button = styled(motion.div)`
  padding: 20px;
  width: 90%;
  font-size: 20px;
  border-radius: 15px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  background-color: ${({ $color }) => $color + "4C"};
  color: ${({ $color }) => $color};
  position: relative;
  font-family: "PoppinsSemiBold";
  box-shadow: 1px 1px 3px rgba(#f27c00, 0.5);
  margin-left: auto;
  margin-right: auto;
`;

export const Avatar = styled(motion.div)`
  padding: 20px;
`;

export const BoxWrapper = styled(motion.div)`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 300px;
`;

export const Box = styled(motion.img)`
  width: 100%;
  z-index: 1;
`;

export const Team = styled(motion.img)`
  position: absolute;
  top: 45%;
  left: 50%;

  width: 100px;
`;

export const Heading = styled(motion.h5)`
  margin: 0;
  color: black;
`;

export const TeamSelect = (props) => {
  const navigate = useNavigate();
  const { team, setTeam, color, setIsStreaming, player } = useContext(PusherContext);

  const [spinner, setSpinner] = useState(true);

  const [teamLocal, setTeamLocal] = useState(null)

  
  const handleOnClick = () => {
    setTimeout(() => {setIsStreaming(true); player?.play();}, 1500)
    setTeam(teamLocal);
    Cookies.set("team", teamLocal);
    navigate("/ready");
  }
  
  useEffect(() => {
    
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/team-select`)
        .then((res) => {
          setTeamLocal(res.data.team)
        });
      const handleTeamSelected = () => {
        setTeam(teamLocal);
      };

      const handleBoxAnimation = () => {
        setSpinner(false);
      };

      setTimeout(handleBoxAnimation, 4300);
      setTimeout(handleTeamSelected, 4100);

  }, []);
  return (
    <TeamSelectWrapper>
      <Heading
       as={motion.h5}
       variants={elements}
       initial={"closed"}
       animate={"open"}
       >Let's find your spirit animal</Heading>
      <BoxWrapper as={motion.div}>
        {spinner && <Box as={motion.img}
        variants={boxAnimation}
        initial={"hidden"}
        animate={ "visible"}
         src={box} />} 
        {teamLocal && (
          <Team
            as={motion.img}
            variants={teamAnimation}
            initial={"hidden"}
            animate={!spinner && "visible"}
            src={require(`../assets/teams/${teamLocal}.png`)}
          />
        )}
      </BoxWrapper>
      <Button 
        $color={color} 
        as={motion.div}
        variants={button}
        initial={"closed"}
        animate={!spinner && "open"}
        onClick={handleOnClick}
      >
        Join your team
      </Button>
    </TeamSelectWrapper>
  );
};
