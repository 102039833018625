export const WaveAnimation = () => {
  return (
    <section className="waveWrapper">
      <div className="air air1"></div>
      <div className="air air2"></div>
      <div className="air air3"></div>
      <div className="air air4"></div>
      <div className="wave-bottom"></div>
    </section>
  );
};