import React, {useEffect, useState, useCallback, useContext} from "react";
import {Player} from '@theolive/player/chromeless'
import { PusherContext } from "../Context";

import styled from "styled-components";
import { motion } from "framer-motion";

 const  VideoWrapper = styled(motion.div)`
  display: flex;
  transform: translateX(50%);
  right: 50%;
  position: absolute;
  z-index: -1;
  top: 0px;
  height: 100vh;
  width: calc(100vh * 1.778);
`;

const LiveStream = () => {

  const { player, setPlayer } = useContext(PusherContext);
  const onMount = useCallback((node) => {
        if (node) {
            setPlayer(new Player(node))
        }
    }, []);

    useEffect(() => {
        return () => {
            player?.destroy()
        }
    }, [player])

    useEffect(() => {
        player?.loadChannel(process.env.REACT_APP_THEO_STREAM_KEY).catch(console.error)
    }, [player])



  return (
    <VideoWrapper>
       <div ref={onMount}/>
    </VideoWrapper>
  );
};

export default LiveStream 