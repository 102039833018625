import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PlayerIcon } from "../icons/PlayerIcon";

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
`;

export const Input = styled(motion.input)`
    border-radius: 16px;
    border: 1px solid rgba(225, 223, 223, 1);
    box-shadow: 0px 4px 40px 0px rgba(136, 122, 166, 0.08);
    height: 56px;
    padding-left: 12px;
    margin-top: 16px;
`;

export const Heading = styled(motion.h3)`
  display:flex;
  align-items: center;
  justify-content: space-between
`;

export const Button = styled(motion.button)`
  width:  100%;
  margin: auto;
  height: 56px;
  border-radius: 12px;
  box-shadow: unset;
  border:0;
  margin-top: 16px;
  background: #f1f1f1;

  font-family: 'Poppins';
  margin-bottom: 32px;

`;  

export const Profile = () => {

  return (
    <Wrapper>
      <Heading><span>My Profile</span><PlayerIcon color={'rgb(112, 112, 112)'} /></Heading>

      <Input placeholder="Email" />
      <Input placeholder="Team" />
      <Button>Logout</Button>
    </Wrapper>
  );
};

