import React from "react";
import styled from "styled-components";
import { PersonSelect } from "./PersonSelect";
import { motion } from "framer-motion";

const Question = styled.h2`
  font-size: 40px;
  margin:0px;
  margin-top:30px;
  text-align: center;
  font-family: "PoppinsSemiBold";
`;

const Wrapper = styled(motion.div)`
  display:contents;

`
const Login = () => {
  return (
    <Wrapper
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Question>Who are you?</Question>
      <PersonSelect />
    </Wrapper>
  );
};

export default Login;
