import React, { useContext } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PusherContext } from "../Context";

export const Button = styled(motion.div)`
  padding: 20px;
  width: 90%;
  font-size: 20px;
  border-radius: 15px;
  margin-bottom: 10px;
  color: white;
  text-align: center;
  background-color: ${({$color}) => $color+'4C'};
  color: ${({$color}) => $color};
  position: relative;
  font-family: "PoppinsSemiBold";
  box-shadow: 1px 1px 3px rgba(#f27c00, 0.5);
  margin-left: auto;
  margin-right: auto;
`;

export const Container = styled(motion.div)`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  flex:1;
  color: black;
  
`;

export const MemberWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-bottom: 30px;
  width: 100%; 
  position: relative;
  margin-top: 30px;
`;

export const MemberImageWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 70px;
  height: 70px;
  background-color: #e5e5e5;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  ${({ $menuExpanded }) => ($menuExpanded ? `width: 50px; height: 50px` : "")}
`;

export const MemberImage = styled(motion.img)`
  position: absolute;
  top: 0;
`;

export const Details = styled(motion.div)`
  
  line-height: 24px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: black;
`;

export const Heading = styled(motion.h5)`
  
  margin:0 ;
 
`;


export const Name = styled(motion.div)`
  width: 100%;
  font-family: PoppinsSemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  flex: 1;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const staggeredChildren = {
  open: {
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    y: 0,
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const elements = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const Confirm = (props) => {
  const { selectedMember, setIsConfirmed, isConfirmed, cancelSelection} =
    props;
  const { setUsername, setIsStreaming, color  } = useContext(PusherContext);
  const handleOnClick = () => {
    setUsername(selectedMember.username);
    setIsConfirmed(!isConfirmed);
  };

  return (
      <Container
        key={'container'}
        as={motion.div}
        variants={staggeredChildren}
        initial={"closed"}
        animate={"open"}
        exit={"closed"}
        transition={{ duration: 0.8 }}
      >
        <Heading key={'heading'} 
        variants={elements}
        >
          Please confirm you are   
        </Heading>   
        <MemberWrapper key={'member'}
        variants={elements}>
          <MemberImageWrapper as={motion.div}>
            <MemberImage
              src={require(`../assets/sweeties/${selectedMember.image}`)}
              as={motion.img}
              alt={selectedMember.username}
              width="100%"
            />
          </MemberImageWrapper>
          <Details>
            <Name>{selectedMember.username}</Name>
          </Details>
        </MemberWrapper>
        <div>
        <Button $color={color} onClick={handleOnClick} key={'confirm'}  variants={elements}>Yes, that's me</Button>
        <Button $color={'#d32f2f'}  onClick={cancelSelection} key={'cancel'}  variants={elements}>O god no!</Button>
        </div>
      </Container>
  );
};
