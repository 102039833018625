import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { normalize } from "styled-normalize";
import App from "./App";
import { createGlobalStyle } from "styled-components";
import cheltnhmitcBt from "./assets/fonts/cheltnhmitc_bt-webfont.woff2";
import MontserratAlternates from "./assets/fonts/MontserratAlternates-SemiBold.woff";  
import LumanosimoRegular from "./assets/fonts/Lumanosimo-Regular.woff";
import {requiresServiceWorker} from "@theolive/player/chromeless";
import PoppinsRegular from "./assets/fonts/Poppins-Regular.woff";  
import  PoppinsSemiBold from "./assets/fonts/Poppins-SemiBold.woff";  
import  Barlow from "./assets/fonts/Barlow-ExtraBoldItalic.woff";  

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'CheltnhmitcBt';
    src: url(${cheltnhmitcBt}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MontserratAlternates';
    src: url(${MontserratAlternates}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'PoppinsSemiBold';
    src: url(${PoppinsSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }


  @font-face {
    font-family: 'LumanosimoRegular';
    src: url(${LumanosimoRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }


  @font-face {
    font-family: 'BarlowBoldItalic';
    src: url(${Barlow}) format('woff');
    font-weight: 400;
    font-style: normal;
  }


  font-family: 'Barlow', sans-serif;

  

  *, *::after, *::before{
    box-sizing: border-box;
    font-family: 'Poppins';
  }
  
  canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .counter{
    font-family: 'PoppinsSemiBold';
    src: url(${PoppinsSemiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }


  // Wave Interface
  .waveWrapper{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    bottom:0;
  }

  .air{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 83px;
    background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
    background-size: 1000px 100px
  }
  .waveWrapper .air.air1{
    animation: wave 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
    
  }
  .waveWrapper .air.air2{
    animation: wave2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
 
  }
  .waveWrapper .air.air3{
    animation: wave 30s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
  }
  .waveWrapper .air.air4{
    animation: wave2 5s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
 
  }

  .wave-bottom{
    width: 100%;
    position: absolute;
    height: 20px;
    bottom: 0;
    background-color: white;

  }
  @keyframes wave{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: 1000px; 
    }
  }
  @keyframes wave2{
    0%{
      background-position-x: 0px; 
    }
    100%{
      background-position-x: -1000px; 
    }
  }


// Fire Background
.fire-wrapper{
  position: absolute;
  bottom: -50px;    
}
.fire {
  font-size: 24px;
  filter: blur(0.02em);
  -webkit-filter: blur(0.02em);
  margin: 3em auto 0 auto;
  position: relative;
  width: 100vw;
  height: 12em;
}

.particle {
  animation: rise 1s ease-in infinite;
  background-image: radial-gradient(#ff5000 20%, rgba(255, 80, 0, 0) 70%);
  border-radius: 50%;
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 5em;
  height: 5em;
}
.particle:nth-of-type(1) {
  animation-delay: 0.4203312687s;
  left: calc((100% - 5em) * 0);
}
.particle:nth-of-type(2) {
  animation-delay: 0.0251532813s;
  left: calc((100% - 5em) * 0.02);
}
.particle:nth-of-type(3) {
  animation-delay: 0.3784508864s;
  left: calc((100% - 5em) * 0.04);
}
.particle:nth-of-type(4) {
  animation-delay: 0.6935046945s;
  left: calc((100% - 5em) * 0.06);
}
.particle:nth-of-type(5) {
  animation-delay: 0.0527787558s;
  left: calc((100% - 5em) * 0.08);
}
.particle:nth-of-type(6) {
  animation-delay: 0.0756137949s;
  left: calc((100% - 5em) * 0.1);
}
.particle:nth-of-type(7) {
  animation-delay: 0.6507479971s;
  left: calc((100% - 5em) * 0.12);
}
.particle:nth-of-type(8) {
  animation-delay: 0.8925961367s;
  left: calc((100% - 5em) * 0.14);
}
.particle:nth-of-type(9) {
  animation-delay: 0.4125980833s;
  left: calc((100% - 5em) * 0.16);
}
.particle:nth-of-type(10) {
  animation-delay: 0.5818567436s;
  left: calc((100% - 5em) * 0.18);
}
.particle:nth-of-type(11) {
  animation-delay: 0.9553827368s;
  left: calc((100% - 5em) * 0.2);
}
.particle:nth-of-type(12) {
  animation-delay: 0.1025915368s;
  left: calc((100% - 5em) * 0.22);
}
.particle:nth-of-type(13) {
  animation-delay: 0.7622327159s;
  left: calc((100% - 5em) * 0.24);
}
.particle:nth-of-type(14) {
  animation-delay: 0.754478763s;
  left: calc((100% - 5em) * 0.26);
}
.particle:nth-of-type(15) {
  animation-delay: 0.4942356645s;
  left: calc((100% - 5em) * 0.28);
}
.particle:nth-of-type(16) {
  animation-delay: 0.9259817904s;
  left: calc((100% - 5em) * 0.3);
}
.particle:nth-of-type(17) {
  animation-delay: 0.9835376704s;
  left: calc((100% - 5em) * 0.32);
}
.particle:nth-of-type(18) {
  animation-delay: 0.1449167151s;
  left: calc((100% - 5em) * 0.34);
}
.particle:nth-of-type(19) {
  animation-delay: 0.1995732562s;
  left: calc((100% - 5em) * 0.36);
}
.particle:nth-of-type(20) {
  animation-delay: 0.1119225865s;
  left: calc((100% - 5em) * 0.38);
}
.particle:nth-of-type(21) {
  animation-delay: 0.8511694999s;
  left: calc((100% - 5em) * 0.4);
}
.particle:nth-of-type(22) {
  animation-delay: 0.5223775889s;
  left: calc((100% - 5em) * 0.42);
}
.particle:nth-of-type(23) {
  animation-delay: 0.3853682299s;
  left: calc((100% - 5em) * 0.44);
}
.particle:nth-of-type(24) {
  animation-delay: 0.1860096017s;
  left: calc((100% - 5em) * 0.46);
}
.particle:nth-of-type(25) {
  animation-delay: 0.5835719644s;
  left: calc((100% - 5em) * 0.48);
}
.particle:nth-of-type(26) {
  animation-delay: 0.5226161989s;
  left: calc((100% - 5em) * 0.5);
}
.particle:nth-of-type(27) {
  animation-delay: 0.267495509s;
  left: calc((100% - 5em) * 0.52);
}
.particle:nth-of-type(28) {
  animation-delay: 0.1555395332s;
  left: calc((100% - 5em) * 0.54);
}
.particle:nth-of-type(29) {
  animation-delay: 0.043712821s;
  left: calc((100% - 5em) * 0.56);
}
.particle:nth-of-type(30) {
  animation-delay: 0.6382433969s;
  left: calc((100% - 5em) * 0.58);
}
.particle:nth-of-type(31) {
  animation-delay: 0.6522103748s;
  left: calc((100% - 5em) * 0.6);
}
.particle:nth-of-type(32) {
  animation-delay: 0.5565718254s;
  left: calc((100% - 5em) * 0.62);
}
.particle:nth-of-type(33) {
  animation-delay: 0.0284543017s;
  left: calc((100% - 5em) * 0.64);
}
.particle:nth-of-type(34) {
  animation-delay: 0.8681514437s;
  left: calc((100% - 5em) * 0.66);
}
.particle:nth-of-type(35) {
  animation-delay: 0.6512872776s;
  left: calc((100% - 5em) * 0.68);
}
.particle:nth-of-type(36) {
  animation-delay: 0.8987696499s;
  left: calc((100% - 5em) * 0.7);
}
.particle:nth-of-type(37) {
  animation-delay: 0.5531815553s;
  left: calc((100% - 5em) * 0.72);
}
.particle:nth-of-type(38) {
  animation-delay: 0.7274848325s;
  left: calc((100% - 5em) * 0.74);
}
.particle:nth-of-type(39) {
  animation-delay: 0.6419284522s;
  left: calc((100% - 5em) * 0.76);
}
.particle:nth-of-type(40) {
  animation-delay: 0.1853587304s;
  left: calc((100% - 5em) * 0.78);
}
.particle:nth-of-type(41) {
  animation-delay: 0.4274621931s;
  left: calc((100% - 5em) * 0.8);
}
.particle:nth-of-type(42) {
  animation-delay: 0.2708828862s;
  left: calc((100% - 5em) * 0.82);
}
.particle:nth-of-type(43) {
  animation-delay: 0.7793589756s;
  left: calc((100% - 5em) * 0.84);
}
.particle:nth-of-type(44) {
  animation-delay: 0.4009274947s;
  left: calc((100% - 5em) * 0.86);
}
.particle:nth-of-type(45) {
  animation-delay: 0.2759467432s;
  left: calc((100% - 5em) * 0.88);
}
.particle:nth-of-type(46) {
  animation-delay: 0.3895755495s;
  left: calc((100% - 5em) * 0.9);
}
.particle:nth-of-type(47) {
  animation-delay: 0.9208382522s;
  left: calc((100% - 5em) * 0.92);
}
.particle:nth-of-type(48) {
  animation-delay: 0.7160391309s;
  left: calc((100% - 5em) * 0.94);
}
.particle:nth-of-type(49) {
  animation-delay: 0.9361668376s;
  left: calc((100% - 5em) * 0.96);
}
.particle:nth-of-type(50) {
  animation-delay: 0.778367398s;
  left: calc((100% - 5em) * 0.98);
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10em) scale(0);
  }
}
`;



if (requiresServiceWorker()) {
  navigator.serviceWorker
      .register("THEOLive.sw.js")
      .then(function (registration) {
          console.log(
              "Registration successful, scope is:",
              registration.scope
          );
      })
      .catch(function (error) {
          console.log("Service worker registration failed, error:", error);
      });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>
);
