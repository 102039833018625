import React, { useContext } from "react";
import { PusherContext } from "../Context";
import { Logo } from "./Logo";
import { LoginCircle } from "./LoginCircle";
import { Background } from "../Styles";
import {  motion, AnimatePresence } from "framer-motion";


const Welcome = () => {
  const { color, step } = useContext(PusherContext);

  return (
    <Background 
      primary={color}
      as={motion.div}
      key={"welcome"}
      initial={false}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      > 
      <AnimatePresence mode={"wait"}>
        {step === "welcome" && (
          <Logo
            key="modal"
            logoDisplay={step === "welcome" ? "visible" : "visible"}
          />
        )}
        {step === "login" && <LoginCircle />}
      </AnimatePresence>
    </Background>
  );
};
export default Welcome;
