import styled from "styled-components";
import { motion } from "framer-motion";

export const ButtonStyle = styled.button`
  border: none;
  border-radius: 5px;
  background-color: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
  
`;

export const Background = styled.button`
  width: 100%;
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.primary};
`;



export const LogoWrapper = styled(motion.div)`
 max-width:80%;
 width: 100%;
`


export const BackgroundImage = styled(motion.div)`
  position: fixed;
  z-index: 0;
  overflow: hidden;
  background-image: url("${(props) => props.image}");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: black;
  width: 100%;
  height: 100%;
  background-position: center;
`;
