import React, { useState, useEffect, useContext } from "react";
import * as components from "../questions";
import { PusherContext } from "../Context";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  width: 100%;
  display:flex;
  height: calc(100% - 117px);
  overflow: hidden;
  position: absolute;
  z-index: 1000
`;



const QuestionComponent = () => {
  const { questionSet } = useContext(PusherContext);
  const [child, setChild] = useState(<></>);

  useEffect(() => {
    if(questionSet){
    const ChildComponent = components[`Question1`];
    ChildComponent && setChild(<ChildComponent />);
    }
  }, [questionSet]);

  return (
    <Container
      as={motion.div}
      key={"question"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {child}
    </Container>
  );
};

export default QuestionComponent;
