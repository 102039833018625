import React, { useContext } from "react";

import { PusherContext } from "../Context";

import {Teams} from "./Teams";
import {MyTeam} from "./MyTeam";

export const MyTeamLeaderboard = () => {
  const { menuExpanded } = useContext(PusherContext);

  return (
    <>
      {menuExpanded && <Teams/>}
      <MyTeam/>
    </>
  );
};
