import React from "react";

export const PlayIcon = ({ color }) => (
  <svg
    width="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="12" cy="12" r="11.5" stroke={color} />
    <path
      d="M10.0039 7.16539C10.2245 7.05513 10.4561 7 10.6988 7C10.9526 7.01103 11.4269 7.17752 11.6145 7.2536C12.85 7.73875 15.2107 9.32651 16.1153 10.2968C16.2697 10.4512 16.4352 10.6386 16.4793 10.6827C16.6668 10.9253 16.7661 11.223 16.7661 11.5439C16.7661 11.8294 16.6779 12.1161 16.5014 12.3477C16.4683 12.3918 16.3138 12.5792 16.1925 12.7005L16.1263 12.7667C15.1997 13.77 12.8941 15.2806 11.7248 15.7658C11.7248 15.7768 11.0298 16.0635 10.6988 16.0745H10.6547C10.1473 16.0745 9.67292 15.7878 9.43023 15.3247C9.29785 15.0711 9.1765 14.3324 9.16547 14.3213C9.06619 13.6598 9 12.6465 9 11.5317C9 10.363 9.06619 9.30446 9.18753 8.65392C9.18753 8.64289 9.30888 8.04748 9.3861 7.84901C9.50745 7.56233 9.72807 7.31976 10.0039 7.16539Z"
      fill={color}
    />
  </svg>
);
