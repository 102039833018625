import React, { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { PusherContext } from "../Context";
import styled from "styled-components";
import gem from "../assets/coin-static.png";
import fire from "../assets/fire.gif";
import constants from '../constants.json';

import ScoreStatus from "./ScoreStatus";

export const MemberWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  ${({ $menuExpanded }) => ($menuExpanded ? `margin-bottom: 20px; ` : "")}
  position: relative;
`;

export const Fire = styled(motion.img)`
  width: 55px;
  height: 55px;
  position: absolute;
  bottom: 6px;
  left: -9px;
`;

export const Placeholder = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  width: 38px;
  height: 38px;
  ${({ $menuExpanded }) => ($menuExpanded ? `width: 50px; height: 50px` : "")}
`

export const MemberImageWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 38px;
  height: 38px;

  border-radius: 50%;
  overflow: hidden;
  position: relative;
  ${({ $menuExpanded }) => ($menuExpanded ? `width: 50px; height: 50px` : "")}
`;

export const TeamLineUp = styled(motion.ul)`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-left: 0;
  flex: 1;
  justify-content: ${({ $menuExpanded }) =>
    $menuExpanded ? "flex-start" : "space-between"};
  width: 100%;
  max-width: 320px;
  ${({ $menuExpanded }) => ($menuExpanded ? `margin-top: 30px; ` : "")}
  ${({ $menuExpanded }) => ($menuExpanded ? `flex-direction: column` : "")}
`;

export const Member = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MemberImage = styled(motion.img)`
  position: absolute;
  top: 0;
`;

export const Details = styled(motion.div)`
  
  line-height: 24px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Score = styled(motion.div)`

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex:1
  font-family: PoppinsSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const Gem = styled(motion.div)`
  margin-right: 16px;
  height: 30px;
  width: 30px;
`;

export const Name = styled(motion.div)`
  width: 100%;
  font-family: PoppinsSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  flex: 1;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const teamWrapper = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const teamLineUp = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const MyTeam = () => {
  const { menuExpanded,  scoreboard, channelMembers, answerStreak } = useContext(PusherContext);
  

 
  console.log( channelMembers, answerStreak )
  return (
    <TeamLineUp
      as={motion.ul}
      variants={teamLineUp}
      initial={"closed"}
      animate={"open"}
      $menuExpanded={menuExpanded}
    >
      {channelMembers &&
        Object.entries(channelMembers.members).map(([k, member]) => {
          if (member.name === "admin" || member.name === "Admin") return null;
          return (
            <MemberWrapper as={motion.div} variants={teamWrapper} key={k}  $menuExpanded={menuExpanded}>
               { answerStreak[k] > 2 &&  <Fire as={motion.img} src={fire} /> }
              <MemberImageWrapper as={motion.div} $menuExpanded={menuExpanded}>
             
                <MemberImage
                  src={require(`../assets/sweeties/${member.image}`)}
                  as={motion.img}
                  alt={member.username}
                  width="100%"
                />
              </MemberImageWrapper>
              <ScoreStatus 
               membersAnswer={scoreboard[k]}
               membersId={k}
     
              />
              {menuExpanded && (
                <Details>
                  <Name>{member.name}</Name>

                  <Score>
                    <Gem as={motion.img} src={gem} alt={""} width="100%" />
                    {member.score.toFixed(2)}
                  </Score>
                </Details>
              )}
            </MemberWrapper>
          );
        })}
        {[...Array(((7 - Object.entries(channelMembers).length)))].map((x, i) => <Placeholder key={i} as={motion.div} $menuExpanded={menuExpanded}/> )}
    </TeamLineUp>
  );
};
