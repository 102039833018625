import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Rewards } from "./Rewards";
import { Profile } from "./Profile";

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Settings = () => {
  return (
    <Wrapper>
      {/* <Profile/> */}
      <Rewards/>
    </Wrapper>
  );
};
