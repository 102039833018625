
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export const ButtonStyle = styled(motion.button)`
  border: none;
  border-radius: 5px;
  background-color: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
  ${({$style}) => {
    if($style === 'close'){
      return (
          `position: absolute;
              top : -43px;
              right: 0;
              z-index:1;
          `
        )
    }
   
  }};
`;

const Button = ({ index, handleButtonClick, value, children, type }) => {
  return (
    <ButtonStyle
      $style={type}
      key={index}
      onClick={(e) => handleButtonClick(e)}
      value={value}
    >
      {children}  
    </ButtonStyle>
  );
  }
export default Button;
