import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PusherContext } from "../Context";
import ReactCountdownClock from "react-countdown-clock";
import StreamPrompt from "../components/StreamPrompt";

export const MemberBackground = styled(motion.div)`
  position: absolute;
  top: -105px;
  left: 50%;
  max-width: 100%;
  width: auto;
  z-index: -1;
  transform: translateX(-50%);
`;
export const ProfileImage = styled(motion.div)`
  background-color: #e5e5e5;
  width: 64px;
  height: 64px;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-32px);
  overflow: hidden;
  border-radius: 50%;
  box-shadow: ${({$questionDisabled}) => $questionDisabled ?  '0px 4px 4px 0px rgba(0, 0, 0, 0)' : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'} ;
`;

export const MemberImage = styled(motion.img)`
  position: absolute;
  top: 0;
`;

export const ClockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const colors = ["orange", "red"];

export const CurrentUserAvatar = () => {
  const { me, setQuestionDisabled, questionDisabled, isStreaming } =
    useContext(PusherContext);
  const [startTime, setStartTime] = useState(null);
  const [color, setColor] = useState(0);
  const [colorInterval, setColorInterval] = useState(null);

  const handleTimeOut = () => {
    setQuestionDisabled(true);
  };
  useEffect(() => {
    setStartTime(Date.now());
    const colorInterval = setInterval(() => {
      setColor((colorOption) =>
        colorOption < colors.length - 1 ? colorOption + 1 : 0
      );
    }, 2500);

    setColorInterval(colorInterval);
    return () => setStartTime(null);
  }, []);

  return (
    <>
      {/* <MemberBackground
        as={motion.img}
        src={require(`../assets/footer-border.png`)}
        alt={""}
        width="100%"
      /> */}
      <ProfileImage>
        {!isStreaming && (  <StreamPrompt /> )}
        {isStreaming && questionDisabled && ( 
          <MemberImage
            as={motion.img}
            src={require(`../assets/sweeties/${me.info.image}`)}
            alt={""}
            $questionDisabled={questionDisabled}
            width="100%"
          />
        )}     
        {isStreaming && !questionDisabled && (
          <ClockWrapper>
            <ReactCountdownClock
              seconds={10}
              color={"red"}
              alpha={1}
              weight={6}
              fontSize={"auto"}
              showMilliseconds={false}
              size={64}
              onComplete={handleTimeOut}
            />
          </ClockWrapper>
        )}
      </ProfileImage>
    </>
  );
};
