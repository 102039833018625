import React from "react";

export const CompassIcon = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M15.5 3C22.4 3 28 8.6 28 15.5C28 22.4125 22.4 28 15.5 28C8.5875 28 3 22.4125 3 15.5C3 8.6 8.5875 3 15.5 3ZM20.3125 11.3875C20.45 10.95 20.05 10.5375 19.6125 10.675L13.2125 12.675C12.95 12.7625 12.7375 12.9625 12.6625 13.225L10.6625 19.6375C10.525 20.0625 10.9375 20.475 11.3625 20.3375L17.7375 18.3375C18 18.2625 18.2125 18.05 18.2875 17.7875L20.3125 11.3875Z"
      fill={color}
    />
  </svg>
);
